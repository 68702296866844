body,
html {
	font-family: Nunito, "Open Sans", "Consolas";
	font-size: 20px;
	//overflow-x: hidden;
}

.modal-header .close {
	cursor: pointer;
}

hr {
	margin-top: 1rem;
	border: 0;
	border-top: 1px solid rgba(0,0,0,.1);

	&.intense {
		border-top: 1px solid rgba(0,0,0,1);
		box-shadow: 0 0 4px 1px #000;
	}
}

.container.container-big {
	@include lg {
		max-width: 1400px;
	}
}

.whiteBox {
	margin: 10px;
	padding: 6px;
	border-radius: 4px;
	box-shadow: 0 3px 9px rgba(0,0,0,.5);
}
.main.imgWrapper {
	max-width: 800px;
	margin: 0 auto;
	img {
		width: 100%;
	}
}
.center-div {
	margin: 10px auto;
	text-align: center;
}
.mb-40 {
	margin-bottom: 40px;
}
.intro {
	background-color: rgba($mc, 0.4);

	.parallax-slider {
		max-height: 55vh;
		height: 800px;
		background: transparent;
		position: relative;

		.introText {
			position: absolute;
			left: 50%;
			top: 65%;
			height: 200px;
			width: 80%;
			margin-left: -40%;
			margin-top: -100px;
			padding: 10px;
			text-align: center;
			color: #fff;

			.title {
				font-size: 48px;
			}

			.slogan {
				margin-bottom: 10px;
			}

			.buttons {
				div {
					margin: 10px;
				}
			}
		}
	}
}

.neubauParallax {
	background: transparent;

	.parallax-slider {
		max-height: 70vh;
		height: 750px;
		background: transparent;
		position: relative;
		@include xl {
			max-height: 95vh;
			height: 900px;
		}
		.slogan {
			text-align: center;
			z-index: 15;
			position: absolute;
			bottom: 20px;
			left: 50%;
			width: 400px;
			margin-left: -200px;
			color: #fff;
			background-color: rgba(0,0,0,0.5);
			border-radius: 6px;
			padding: 6px;
		}
	}
}
// .news {
// 	//height: 540px;
// 	margin: 20px 40px;
// 	.owl-carousel {
// 		max-width: 1280px;
// 		margin: auto;
// 		.news-item {
// 			position: relative;
// 			@include flexbox;
// 			justify-content: space-between;
// 			align-items: center;
// 			justify-content: center;
// 			@include md {
// 				height: 650px;
// 			}
// 			@include lg {
// 				height: 450px;
// 			}
// 			.row {
// 				width: 100%;
// 			}
// 			.newsSlogan {
// 				border-top-right-radius: 12px;
// 				background: rgba(255,255,255,0.5);
// 				@include flexbox;
// 				flex-direction: column;
// 				justify-content: center;
// 				@include md {
// 					width: 55%;
// 				}
// 				text-align: left;
// 				.title {
// 					font-size: 28px;
// 				}
// 				.showMore {
// 					text-align: left;
// 					margin-top: 10px;
// 				}
// 			}
// 			.newsImg {
// 				img {
// 					max-height: 500px;
// 					//width: 100%;
// 				}
// 			}
// 		}
// 	}
// }

.overview {
	margin: 80px 20px;
}

.theater-trailer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;
	background-color: rgba($mc, .1);
	border-radius: 4px;
	box-shadow: 1px 1px 6px rgba(#000, .6);
	padding: 20px;
	> div {
		margin: 10px 0;
	}
	.trailer {
		max-width: 680px;
	}
	video {
		max-width: 100%;
	}
}

$informationheight: 580px;
$informationheightsmall: 680px;
.information {
	z-index: 2;
	margin: 80px 0;
	position: relative;
	height: $informationheightsmall;
	@include sm {
		height: $informationheight;
	}
	.overlay {
		z-index: 1;
		position: absolute;
		overflow: hidden;
		height: $informationheightsmall;
		@include sm {
			height: $informationheight;
		}
		top: 0;
		left: 0;
		width: 100%;
		@include flexbox;
		justify-content: center;
		align-items: center;
		img {
			height: 100%;
			width: auto;
			@include md {
				width: 100%;
				height: auto;
			}
		}
	}
	.text {
		box-shadow: inset 0 10px 40px 1px #000, inset 0 -10px 40px 1px #000;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		height: $informationheightsmall;
		@include sm {
			height: $informationheight;
		}
		width: 100%;
		.important {
			background-color: rgba(#f00, .2);
			padding: 6px 12px;
			border-radius: 4px;
			@include transition(.8s);
		}
		.box {
			margin: 30px 0;
			@include sm {
				margin: 60px 0;
			}
			background-color: rgba(#000, .6);
			border-radius: 10px;
			color: #fff;

			&:hover .important {
				background-color: rgba(#f00, .5);
			}
			.title {
				font-size: 48px;
				text-align: center;
			}
			.block {
				margin: 4px 0;
				text-align: center;
				font-size: 22px;
				&.big {
					font-size: 28px;
				}
				&.small {
					font-size: 16px;
				}
			}
			.image {
				text-align: center;
				@include flexbox;
				justify-content: center;
				align-items: center;
				display: none;
				@include lg {
					@include flexbox;
				}
			}
		}
	}
}

#numbersGoUp {
	background-color: rgba($mc-dark2, 1);
	min-height: 380px;
	color: #fff;
	text-align: center;
	margin: 60px 0 0;
	padding-top: 35px;
	padding-bottom: 10px;
	.row {
		max-width: $xl;
		margin: 0 auto;
	}
	.col-sm-4 {
		&:hover {
			.countUp {
				transform: rotate3d(0, 1, 0, 180deg);
				background-color: $sc-light2;
				color: $sc-light2;
				.press-here {
					opacity: 1;
					color: #fff;
					transition: .5s .5s;
				}
			}
		}
	}
	a {
		color: #fff;
		&:hover {
			color: #fff;
			text-decoration: none;
		}
	}
	.countUp {
		padding: 10px;
		background-color: #666;
		background-color: $mc-light2;
		border-radius: 50%;
		height: 120px;
		width: 120px;
		@include flexbox;
		align-items: center;
		justify-content: center;
		font-size: 44px;
		margin: 40px auto;
		transition: all .5s, transform 1s;
		transform: rotate3d(0, 1, 0, 0deg);
		cursor: pointer;
		&:hover {
			box-shadow: 0 2px 8px 8px rgba(0,0,0,.5);
		}
		.press-here {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: .5s;
			transform: rotate3d(0, 1, 0, -180deg);
			font-size: 22px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			pointer-events: none;
		}
	}

	.title {
		font-size: 32px;
	}

	.text {
		font-size: 16px;
	}
}

.topOffer {
	margin: 40px 0;

	> .title {
		text-align: center;
		font-size: 32px;
	}
	.cardBox {

			.cardTextWrapper {
				.cardText {
					min-height: 240px;
				}
				.cardBtn {
				margin-top: auto;
			}
		}
	}
}

.events {
	min-height: 600px;
	height: 790px;
	@include xl {
		height: 900px;
	}
	overflow: hidden;
	position: relative;
	background: url("../img/gaukler2.png") 50% 40%;

	#eventVid {
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		bottom: 0;
		@media (min-width: 1780px) {
			bottom: -10%;
		}
	}

	.eventText {
		position: absolute;
		left: 0;
		top: 50%;
		height: 200px;
		margin-top: -100px;
		width: 100%;
		padding: 10px 10px 10px 30px;
		background-color: rgba(0,0,0,0.4);
		color: #fff;
		text-align: center;
		font-size: 24px;

		.title {
			font-size: 62px;
			margin-bottom: 20px;
		}

		.slogan {
			margin-bottom: 10px;
		}

		.buttons {
			div {
				margin: 10px;
			}
		}
	}
}

.outro {
	margin: 20px 0;
	padding: 20px 0;
	text-align: center;
}

.footer {
	min-height: 300px;
	background-color: rgba($mc,0.9);
	color: #fff;
	padding-top: 10px;
	box-shadow: 0 -2px 8px rgba(0,0,0,.9);
	padding: 0 15px;
	ul.menu {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			margin-top: 10px;
		}
	}
	.facebookicon {
		width: 100%;
		height: 100px;
	}
	.socialIcon {
		margin: 10px;
		padding: 2px 12px;
		border: 2px solid $mc-light2;
		float: left;
		border-radius: 4px;
		color: $mc-light2;

		&:hover {
			background-color: $mc-light2;
			color: #fff;
		}
	}

	.linkCol {
		.ad {
			margin-top: 20px;
			a {
				&:before {
					content: '';
					display: none;
				}
				img {
					width: 80%;
				}
			}
		}
		.menu ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin-top: 10px;
			}
		}
		> div {
			margin-top: 10px;
		}

		a {
			color: rgba(255,255,255,.5);
			position: relative;
			@include transition(0.3s, ease-in-out);

			&:before {
				content: "";
				position: absolute;
				height: 2px;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: #fff;
				visibility: hidden;
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
				-webkit-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
			}

			&:hover {
				color: #fff;
				text-decoration: none;

				&:before {
					visibility: visible;
					-webkit-transform: scaleX(1);
					transform: scaleX(1);
				}
			}
		}
	}
}

.btn-primary {
	background-color: $mc-light2;

	&.disabled {
		background-color: grayscale($mc);
		border: none;

		&:hover {
			background-color: grayscale($mc);
		}
	}

	&:hover {
		background-color: $mc;
	}
}
.agegroupfilter {
	width: 100%;
	font-size: 18px;
	line-height: 28px;
	min-width: 200px;
	border-radius: 6px;
	padding: 0;
	@include defBoxShadow;
	@include defBorder;
	height: 38px;
	cursor: pointer;
	@include transition(.5s);
	position: relative;
	overflow: hidden;
	background-size: 100% 100px;
	&:hover {
		background-color: #afa;
	}
	>div {
		// @include transition(1s);
		white-space: nowrap;
		padding: 4px;
		@include flexbox;
		justify-content: center;
		align-items: center;
		position: absolute;
		@include noselect;
		text-align: center;
		width: 100%;
	}
	.age0 {
		top: 0px;
		@include transition(1s);
	}
	.age1 {
		top: 30px;
		@include transition(0s);
	}
	.age2 {
		top: -30px;
		@include transition(1s);
	}
	&.age1 {
		.age0 {
			top: -30px;
		}
		.age1 {
			top: 0;
			@include transition(1s);
		}
		.age2 {
			top: 30px;
			@include transition(0s);
		}
	}
	&.age2 {
		.age0 {
			top: 30px;
			@include transition(0s);
		}
		.age1 {
			top: -30px;
			@include transition(1s);
		}
		.age2 {
			top: 0px;
			@include transition(1s);
		}
	}
}
.courseCat,
.offerCat {
	margin: 40px 0;

	> ul {
		list-style: none;
		margin-top: 10px;
		padding-left: 10px;

		li {
			position: relative;
			height: 50px;

			.center {
				position: absolute;
				/*		top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);*/
			}
		}
	}
}

.courseWrapper {
	margin: 40px 0;

	> .title {
		text-align: center;
		font-size: 32px;
	}

	.courseBox {
		margin-bottom: 40px;
		position: relative;
		border-radius: 6px;
		box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
		@include transition(0.3s);
		@include flexbox;
		flex-direction: column;
		min-height: 380px;
		@include xl {
		min-height: 360px;
		}
		.courseImg {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			overflow: hidden;
			line-height: 200px;
			overflow: hidden;
			text-align: center;

			img {
				height: auto;
				width: 100%;
				// min-height: 105%;
				// min-width: 100%;
				// max-width: 100%;
				// max-height: 120%;
				// width: auto;
				margin: -100%;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
			}

			&.disabled {
				position: relative;

				img {
					-webkit-filter: grayscale(100%);
					/* Safari 6.0 - 9.0 */
					filter: grayscale(100%);
				}

				&:after {
					position: absolute;
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					content: '\f00d';
					font-size: 180px;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					color: #822;
					opacity: 0.8;
				}
			}

			.courseLabel {
				position: absolute;
				top: 5px;
				right: 5px;
				line-height: 20px;
				color: #fff;
				background-color: #28a745;
				border-radius: 0.25rem;
				font-size: 22px;
				font-weight: 700;
				padding: 0.25em 0.4em;
				opacity: 0.8;

				&.type1 {
					background-color: #28a745;
				}

				&.type2 {
					background-color: #FF7707;
				}

				&.type3 {
					background-color: #dc3545;
				}

				&.type4 {
					background-color: $mc;
				}
			}
		}

		.courseTextWrapper {
			//background-color: #e2e2e2;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
			padding: 10px;
			flex: 1;
			@include flexbox;
			flex-direction: column;
			justify-content: flex-start;
			.courseTitle {
				text-align: left;
				font-size: 22px;
			}

			.courseText {
				font-size: 14px;
				text-align: justify;
				// min-height: 140px;
				@include flexbox;
				flex-direction: column;
				justify-content: space-between;
				overflow: hidden;
			}

			.courseBtn {
				margin-top: auto;
				text-align: center;
			}
		}

		&:hover {
			box-shadow: 0 5px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
			top: -1px;
		}

		hr {
			margin-top: 0.4rem;
			border: 0;
			border-top: 1px solid rgba(0,0,0,.1);

			&.intense {
				border-top: 1px solid rgba(0,0,0,1);
				box-shadow: 0 0 4px 1px #000;
			}
		}
	}
}

.offerWrapper {
	margin: 40px 0;

	> .title {
		text-align: center;
		font-size: 32px;
	}

	.col-xxl-3 {
		@media (min-width: $xxl) {
			max-width: 25%;
		}
	}

	.offerBox {
		margin-bottom: 40px;
		position: relative;
		border-radius: 6px;
		box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
		@include transition(0.3s);
		min-height: 370px;
		@include xl {
			min-height: 350px;
		}
		@include flexbox;
		flex-direction: column;
		justify-content: flex-start;

		.offerImg {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			overflow: hidden;
			height: 200px;
			line-height: 200px;
			overflow: hidden;
			text-align: center;
			position: relative;

			img {
				// width: 100%;
				// height: auto;
				height: auto;
				width: 100%;
				margin: -100%;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
			}

			&.disabled {
				position: relative;

				img {
					-webkit-filter: grayscale(100%);
					/* Safari 6.0 - 9.0 */
					filter: grayscale(100%);
				}

				&:after {
					position: absolute;
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					content: '\f00d';
					font-size: 180px;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					color: #822;
					opacity: 0.8;
				}
			}

			.offerLabel {
				position: absolute;
				top: 5px;
				right: 5px;
				line-height: 20px;
				color: #fff;
				background-color: #28a745;
				border-radius: 0.25rem;
				font-size: 16px;
				font-weight: 700;
				padding: 0.25em 0.4em;
				opacity: 0.8;

				&.type1 {
					background-color: #28a745;
				}

				&.type2 {
					background-color: #FF7707;
				}

				&.type3 {
					background-color: #dc3545;
				}

				&.type4 {
					background-color: $mc;
				}
			}
			.agegroups {
				position: absolute;
				bottom: 5px;
				right: 5px;
				line-height: 20px;
				color: #fff;
				border-radius: 6px;
				font-size: 16px;
				font-weight: 700;
				padding: 0.25em 0.4em;
				opacity: 0.8;
				color: #000;
				@include transition(.8s);
				@include defBorder;
				@include defBoxShadow;
				background-color: rgba(#fff, .6);

				.fa-male {
					font-size: 42px;
					position: relative;
					pointer-events: none;
				}
				&.type2 .fa-male {
					padding: 0 6px;
				}
				&.type3 .fa-male {
					padding-left: 5px;
				}
				.fa-child {
					font-size: 26px;
					pointer-events: none;
				}
				&:hover {
					background-color: rgba(#fff, .9);
					color: #222;
				}
			}
		}

		.offerTextWrapper {
			//background-color: #e2e2e2;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
			padding: 10px;
			flex: 1;
			@include flexbox;
			flex-direction: column;
			justify-content: flex-start;

			.offerTitle {
				text-align: left;
				font-size: 22px;
			}

			.offerText {
				font-size: 14px;
				text-align: justify;
				// min-height: 140px;
				@include flexbox;
				flex-direction: column;
				justify-content: space-between;
				overflow: hidden;
			}

			.offerBtn {
				text-align: center;
				margin-top: auto;
			}

			hr {
				margin-top: 0.4rem;
				border: 0;
				border-top: 1px solid rgba(0,0,0,.1);

				&.intense {
					border-top: 1px solid rgba(0,0,0,1);
					box-shadow: 0 0 4px 1px #000;
				}
			}
		}

		&:hover {
			box-shadow: 0 5px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
			top: -1px;
		}
	}
}

.mainWrapper {
	position: relative;
	min-height: 1200px;
}

.whitePage {
	box-shadow: 0 2px 4px #000;
	border-radius: 6px;
	padding-bottom: 20px;
	margin: 40px auto 80px;
	background-color: #fff;

	&.notmobil {
		@include maxmd {
			box-shadow: none;
			margin: 0;
			width: 100%;
			max-width: none;
		}
	}
}

#courseContainer,
#neubauContainer,
#offerContainer {
	position: relative;
	left: 0;
	@include transition(left 1s);
}

.container#offerContainer {
	@include md {
		max-width: none;
	}
}

body.slideOut {
	overflow: hidden;

	#courseContainer,
	#neubauContainer,
	#offerContainer {
		left: -100%;
		overflow: hidden;
	}
}

#courseContainer {
	.courseInfo {
		font-size: 14px;
		margin-top: 20px;

		> div.col-sm-3 {
			font-size: 18px;
		}

		> div.col-sm-9 {
			margin-bottom: 10px;
		}

		.bigText {
			font-size: 24px;
		}

		.bittebeachtenWrapper {
			.bittebeachten {
				max-width: 50%;
				@include sm {
					max-width: 100%;
				}
				@include md {
					position: absolute;
					top: -10px;
					right: 0;
				}
			}
		}
	}
}

.toggleCourseInfo {
	margin-top: 15px;
}

.courseModal,
.offerModal {
	z-index: 1;
	position: fixed;
	top: 85px;
	display: block !important;
	right: auto;
	bottom: auto;
	width: 100%;
	height: 100%;
	left: 100%;
	padding-bottom: 50px;
	overflow: visible;
	background-color: #fff;
	@include transition(left 1s);

	.modal-dialog {
		margin: 0 auto;
		max-width: none;
		background: #fff;
	}

	&.slideIn {
		left: 0;
		position: fixed;
		top: 85px;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.modal-header {
		justify-content: flex-start;
		position: relative;

		.back {
			color: #fff;
			cursor: pointer;
			margin-right: 15px;
			margin-left: 0;
			background-color: $fc;
			text-shadow: none;
			border: none;
			padding: 4px 16px;
			border-radius: 4px;
			position: absolute;
			bottom: 10px;
			left: 10px;
			line-height: 30px;
			z-index: 30;
			@include transition(0.3s);

			&:hover {
				background-color: $fc-light3;
			}
		}

		.courseTitle,
		.modalTitle,
		.offerTitle {
			position: absolute;
			text-align: left;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 10px 0 10px 65px;
			margin: 0;
			background-color: rgba(255,255,255,0.3);
		}

		.modal-img {
			position: relative;
			height: 300px;
			line-height: 300px;
			overflow: hidden;
			text-align: center;
			margin: 0 auto;
			img {
				//min-height: 100%;
				min-width: 100%;
				max-height: 100%;
				@include md {
					max-width: 100%;
					max-height: inherit;
				}
				margin: -100%;
			}

			.shadow {
				position: absolute;
				width: 100%;
				height: 100%;
				box-shadow: inset 0 -15px 16px -10px #000;
				top: 0;
				left: 0;
			}
		}
	}
	.back {
		color: #fff;
		cursor: pointer;
		margin-right: 15px;
		margin-left: 0;
		background-color: $mc-light2;
		text-shadow: none;
		border: none;
		padding: 4px 16px;
		border-radius: 4px;
		@include transition(0.3s);

		&:hover {
			background-color: $mc;
		}
	}
	.modal-footer {
		justify-content: flex-start;
		.back {
			color: #fff;
			cursor: pointer;
			margin-right: 15px;
			margin-left: 0;
			background-color: $mc-light2;
			text-shadow: none;
			border: none;
			padding: 4px 16px;
			border-radius: 4px;
			@include transition(0.3s);

			&:hover {
				background-color: $mc;
			}
		}
	}

	.modal-dialog {
		padding-bottom: 80px;
		// @include md {
		// 	max-width: 700px;
		// }
		// @include lg {
		// 	max-width: 900px;
		// }
		// @include xl {
		// 	max-width: 1100px;
		// }

		.modal-content {
			border: none;
		}
	}

	.modal-body {
		.blockText {
			margin-bottom: 20px;
		}

		.blockDetails {
			margin-bottom: 10px;

			i {
				float: left;
				margin-top: 4px;
				width: 40px;
				text-align: center;
			}

			> div {
				margin-left: 40px;
			}
		}
	}
}

.label_check {
	//flex-basis: 2em;
	flex-shrink: 0;
	display: inline-block;
	border-radius: 50%;
	border: 5px solid rgba(0,0,0,0.1);
	background: white;
	vertical-align: middle;
	margin-right: 10px;
	width: 2em;
	height: 2em;
	cursor: pointer;
	@include flexbox;
	align-items: center;
	justify-content: center;
	transition: border 0.3s ease;

	i.icon {
		opacity: 0.2;
		font-size: 1rem;
		//font-size: '(1rem + 1vw)';
		color: transparent;
		transition: opacity 0.3s 0.1s ease;
		-webkit-text-stroke: 3px rgba(0,0,0,.5);
	}

	&:hover {
		border: 5px solid rgba(0,0,0,0.2);
	}
}

.label_checkbox {
	display: none;
}

.label_checkbox:checked + .label_check {
	width: 2em;
	height: 2em;
	background: #00d478;
	border: 0;
	opacity: 1;

	.icon {
		color: white;
		-webkit-text-stroke: 0;
		opacity: 1;
		transform: scale(1);
	}
}

.label {
	@include flexbox;
	justify-content: flex-start;
}

.label_text {
	margin-top: 8px;
	cursor: pointer;
	line-height: 18px;

	&:before {
		content: "";
		position: absolute;
		height: 4px;
		bottom: 2px;
		left: 48px;
		right: -2px;
		border-radius: 2px;
		background-color: $sc;
		visibility: hidden;
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	&:hover:before {
		visibility: visible;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

.loginBox {
	max-width: 300px;
	margin: 60px auto;

	.info {
		margin: 20px auto;
		padding: 10px;
		background-color: $mc-light3;
		border-radius: 6px;
	}
}

#loginModal {
	form {
		width: 100%;
	}

	.loginForm {
		left: -900px;
		@include transition(1s);

		&.open {
			left: 0;
		}
	}

	.registerForm {
		position: absolute;
		top: 0;
		left: 900px;
		width: 100%;
		@include transition(1s);

		&.open {
			left: 0;
		}
	}
}

.itemBox {
	border: 1px solid $mc-light;
	border-radius: 6px;
	margin-bottom: 10px;
	padding: 10px;

	.description {
		width: 100%;
	}

	.btn {
		float: right;
		margin-left: 10px;
	}
}

#theater {
	.fieldset {
		border: 1px solid $mc;
		border-radius: 4px;
		padding: 8px;
		margin-bottom: 10px;
	}
}

.perfOverview {
	position: absolute;
	top: 5%;
	left: 5%;
	width: 90%;
	height: 90%;
	background-color: #fff;
	padding: 10px;
	overflow: visible;

	i {
		@media print {
			display: none;
		}
	}

	table td,
	table th {
		padding: 6px;
	}

	tr.gray {
		background-color: #ddf;
	}
}

.smText {
	font-size: 16px;
}

.headerImage {
	position: relative;
	margin-bottom: 30px;
	overflow: hidden;
	margin-left: -15px;
	margin-right: -15px;
	//padding-top: 15px;
	border-radius: 4px;

	img {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		margin-left: 50%;
		transform: translateX(-50%);
		max-height: 400px;
		width: auto;
	}

	.title {
		position: absolute;
		bottom: 0;
		font-size: 2.5rem;
		line-height: 2.5rem;
		padding: 0 0 10px 20px;
		color: #000;
		//text-shadow: 1px 1px 4px #fff;
		background-color: rgba(#fff, .4);
		box-shadow: 0 -4px 16px #000;
		width: 100%;
	}
}

.chronik,
.gebaeude,
.neubau,
.organisation,
.vorstand {
	background-color: #f0fbff;
}

.chronikbox {
	background-color: #f0fbff;

	.row:nth-child(even) {
		.col-sm-4:nth-of-type(even) .block {
			background-color: $mc;
			@include backgroundTartan();
			background-position: random(50)+px;
		}

		.col-sm-4:nth-of-type(odd) .block {
			background-color: $mc;
			@include backgroundTartan();
			background-position: random(50)+px;
		}
	}

	.row:nth-child(odd) {
		.col-sm-4:nth-of-type(even) .block {
			background-color: $mc;
			@include backgroundTartan();
			background-position: random(50)+px;
		}

		.col-sm-4:nth-of-type(odd) .block {
			background-color: $mc;
			@include backgroundTartan();
		}
	}

	.col-sm-4 {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.block {
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 10px;
		height: 100%;
		//background-color: $mc-light3;
		.year {
			font-size: 2.5em;
			line-height: 1em;
			//font-family: 'Indie Flower';
			//font-weight: 700;
		}
	}
}

.gebaeudeslider,
.neubauslider {
	position: relative;

	.row {
		margin-bottom: 40px;
	}

	#expandButton {
		z-index: 100;
		position: absolute;
		top: 5px;
		right: 5px;
		background-color: #fff;
		border: 2px solid #000;
		border-radius: 4px;
		padding: 2px 8px;
		opacity: 0.5;
		@include transition(0.3s);
		cursor: pointer;

		&:hover {
			opacity: 1;
			background-color: #aaa;
		}
	}

	.owl-carousel {
		&.expand {
			.owl-item img {
				max-height: 90vh;
				max-width: 100%;
			}
		}

		.owl-item img {
			@include transition(1s);
			max-height: 450px;
			width: auto;
			margin: auto;
		}
	}
}

.owl-carousel.owl-full-width {
	max-height: 90vh;
	max-width: 100%;

	embed,
	object {
		width: 100%;
		height: 90vh;
	}

	.owl-item img {
		@include transition(1s);
		max-height: 500px;
		width: auto;
		margin: auto;
	}
}

.satzung {
	.accordion {
		.title {
			position: relative;
			cursor: pointer;
			width: 100%;
			color: #fff;
			border-radius: 4px;
			background: linear-gradient(0deg, $fc-light 0%, $mc 20%, $mc-dark2 100%);
			@include flexbox;
			justify-content: flex-start;
			padding: 4px;
			margin-bottom: 10px;

			> div {
				margin-left: 10%;
				z-index: 2;
				min-width: 100px;
			}

			&:after {
				position: absolute;
				z-index: 1;
				content: ' ';
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				border-radius: 4px;
				background: linear-gradient(180deg, $fc-light2 0%, $mc 20%, $mc-dark2 100%);
				opacity: 0;
				@include transition(0.3s);
			}

			&:hover:after {
				opacity: 1;
			}
		}

		.content {
			display: none;
			width: 100%;
			font-size: 18px;

			> ol {
				margin-left: 15px;

				> li {
					padding-left: 20px;
					margin: 10px;

					> ol {
						padding-left: 20px;

						> li {
							padding-left: 10px;
						}
					}
				}
			}
		}
	}
}

.theater {
	position: relative;
	min-height: 850px;

	.curtain {
		list-style: none;
		position: relative;
		padding-left: 40px;

		li:before {
			content: '';
			margin: 0;
			height: 25px;
			width: 25px;
			margin-top: 2px;
			position: absolute;
			left: 0;
			list-style: none;
			background-image: url("../img/theater.jpg");
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 25px;
		}
	}

	#lastTheaterImg {}

	#blueimp-gallery-carousel {
		height: 542px;
	}

	.theatervorhang {
		position: absolute;
		top: 0;
		left: -100px;
		clip: rect(0px,566px,849px,100px);
		overflow: hidden;
		z-index: 1;
		display: none;
		@include md {
			display: block;
			left: -100px;
			clip: rect(0px,566px,849px,100px);
		};
		@include lg {
			display: block;
			left: -100px;
			clip: rect(0px,566px,849px,100px);
		};
	}

	.content {
		&.paddingleft {
			padding-right: 20px;
			@include md {
				padding-left: 45%;
			}
			@include lg {
				padding-left: 25%;
			}
		}
		text-align: center;
		z-index: 2;

		> div {
			margin-bottom: 10px;
		}

		.leftText {
			text-align: left;
		}

		.blockText {
			text-align: justify;
			margin-bottom: 20px;
			line-height: 26px;
		}

		.smallText {
			font-size: 16px;
		}

		.centerText {
			&.big {
				font-size: 24px;
				font-weight: 700;
			}
		}

		.dates {
			margin: auto;
			background: #fcc;
			border: 2px solid #400;
			border-radius: 4px;
			padding: 10px;
			max-width: 500px;
			line-height: 26px;

			.centerdiv {
				margin: 0 2%;
				@include flexbox;
				text-align: left;
				justify-content: space-between;
				&.theater-info {
					flex-direction: column;
					text-align: center;
				}
			}
		}
	}
}

.reservation {
	position: relative;
	overflow: visible;
	min-height: 1060px;

	.green {
		background-color: rgba(green, 0.5);
		width: 50px;
		float: left;
		text-align: center;
	}

	.yellow {
		background-color: yellow;
		width: 50px;
		float: left;
		text-align: center;
	}

	.red {
		background-color: rgba(lighten(red, 10%), 0.8);
		width: 50px;
		float: left;
		text-align: center;
	}

	#toast {
		border: 2px solid $mc-dark3;
		border-radius: 6px;
		position: fixed;
		width: 50%;
		margin-left: -25%;
		color: #fff;
		background-color: $mc-dark;
		text-align: center;
		padding: 20px 10px;
		position: fixed;
		bottom: 30px;
		left: 50%;
		z-index: 500;
		@include animation-duration(1s);

		&.hidden {
			display: none;
		}
	}

	.dates {
		position: absolute;

		.selectDate:hover .selectDateWrapper {
			cursor: pointer;
			//margin-bottom: 15px;
		}

		.selectDateWrapper {
			height: 50px;
			margin-bottom: 15px;
			width: 450px;
			position: relative;

			&:hover .default-state {
				bottom: 100%;
			}

			&:hover .active-state {
				top: 0;
			}
			overflow: hidden;

			.default-state {
				@include transition(0.5s);
				height: 50px;
				width: 450px;
				//background-color: $fc-light3;
				border: 2px solid $mc-light;
				border-radius: 4px;
				padding: 6px;
				z-index: 1;
				position: absolute;
				bottom: 0;
			}

			.active-state {
				@include transition(0.5s);
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: 50px;
				background-color: $sc;
				color: #fff;
				border: 2px solid $sc;
				border-radius: 4px;
				padding: 6px;
			}
		}
	}

	.performance {
		position: absolute;

		.title {
			@include flexbox;
			justify-content: space-between;
		}

		.stage {
			margin: 20px 10px 10px;
			flex-basis: 100%;
			height: 80px;
			line-height: 80px;
			width: 100%;
			background-color: $mc-light;
			color: #fff;
			font-size: 32px;
			text-align: center;
		}

		.performanceRoom {
			padding: 10px;
			@include flexbox;
			flex-direction: row;
			flex-wrap: nowrap;
			@include maxmd {
				font-size: 16px;
			}

			.roomCol {
				margin: 0 2px;
				@include flexbox;
				flex-direction: column;
				justify-content: flex-start;

				&.leftSpace {
					margin-left: 15px;
				}

				.chair {
					text-align: center;
					border: 1px solid #aaa;
					padding: 0 2px;
					min-width: 35px;
					height: 35px;
					margin: 2px 0;
					line-height: 35px;
					@include maxmd {
						height: 25px;
						min-width: 25px;
						line-height: 25px;
					}

					&.free {
						background-color: rgba(green, 0.5);
						cursor: pointer;

						&:hover {
							background-color: rgba(darken(green, 10%), 0.7);
						}

						&.selected {
							//background-color: rgba(darken(green, 10%), 0.7);
							background-color: rgba(darken(aqua, 10%), 0.8);
						}
					}

					&.disabled {
						background-color: yellow;
						cursor: no-drop;
					}

					&.blocked {
						background-color: rgba(lighten(red, 10%), 0.8);
						cursor: no-drop;
					}
				}

				.table {
					text-align: center;
					color: #fff;
					background-color: #a5530e;
					padding: 0 2px;
					margin: 2px 0;
					min-width: 45px;
					min-height: 347px;
					line-height: 347px;
					@include maxmd {
						min-width: 25px;
						min-height: 257px;
						line-height: 257px;
					}
				}

				.floor {
					padding-top: 50px;
				}
			}
		}
	}

	.formular {
		position: absolute;
		right: 0;

		.form-control {
			max-width: 400px;
		}

		#emailHelp {
			max-width: 400px;
		}
	}
}

.form-check-input {
	margin-left: 0;
	margin-top: 0.4rem;
}

.datetimepicker td,
.datetimepicker th {
	height: 35px;
	width: 45px;
}

.btn {
	cursor: pointer;
}

.tooltip {
	line-height: 22px;

	.tooltip-inner {
		max-width: 300px;
		width: 300px;
	}
}

#programmContainer {
	.mg-top {
		margin-top: 30px;

		> div:nth-child(1) {
			font-size: 24px;
		}
	}

	.progline:nth-child(even) {
		background-color: #eee;
	}
}

#app {
	@media print {
		display: none;
	}
}

.neubau {
	.smallText {
		font-size: 18px;
	}

	.headerImage {
		height: 300px;
		@include md {
			height: 350px;
		}
		@include lg {
			height: 545px;
		}
		@include xl {
			height: 615px;
		}
	}

	.title {
		z-index: 11;
	}

	video {
		width: 100%;
		z-index: 10;
		position: absolute;
		top: 0;
		@include xl {
			top: -25px;
		}
		margin-left: 50%;
		transform: translateX(-50%);
		width: 100%;
	}
}

.cardBox {
	margin-bottom: 40px;
	position: relative;
	border-radius: 6px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
	@include transition(0.3s);

	.cardImg {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		overflow: hidden;
		height: 200px;
		line-height: 200px;
		overflow: hidden;
		text-align: center;

		img {
			min-height: 105%;
			min-width: 105%;
			max-width: 100%;
			height: auto;
			margin: -100%;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}

		.cardLabel {
			position: absolute;
			top: 5px;
			right: 5px;
			line-height: 20px;
			color: #fff;
			background-color: #28a745;
			border-radius: 0.25rem;
			font-size: 14px;
			font-weight: 700;
			padding: 0.25em 0.4em;
			opacity: 0.8;

			&.type1 {
				background-color: #28a745;
			}

			&.type2 {
				background-color: #FF7707;
			}

			&.type3 {
				background-color: #dc3545;
			}

			&.type4 {
				background-color: $mc;
			}
		}
	}

	.cardTextWrapper {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		padding: 10px;

		.cardTitle {
			text-align: left;
			font-size: 28px;

			&.centerText {
				text-align: center;
			}
		}

		hr {
			margin-top: 0.4rem;
			margin-bottom: 0.8rem;
			border: 0;
			border-top: 1px solid rgba(0,0,0,.1);

			&.intense {
				border-top: 1px solid rgba(0,0,0,1);
				box-shadow: 0 0 4px 1px #000;
			}
		}

		.cardText {
			font-size: 14px;
			text-align: justify;
			min-height: 140px;
			@include flexbox;
			flex-direction: column;
			justify-content: space-between;
			overflow: hidden;
		}

		.cardBtn {
			text-align: center;
		}
	}

	&:hover {
		box-shadow: 0 5px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
		top: -1px;
	}
}

.kontakt {
	form {
		width: 100%;
		@include md {
			width: 50%;
		}

		.form-group {
			width: 100%;
		}
	}

	.successMail {
		border: 2px solid $sc;
		border-radius: 4px;
		background-color: $sc-light2;
	}

	.failMail {
		border: 2px solid $sc-dark;
		border-radius: 4px;
	}
}

#carouselControls.news {
	height: 500px;
	max-width: 1140px;
	margin: 0 auto;
	overflow: hidden;

	.carousel-control-next,
	.carousel-control-prev {
		@include transition(0.8s);

		&:hover {
			background-color: rgba(#000, 0.4);
		}
	}

	.carousel-indicators {
		min-height: 30px;
		align-items: flex-end;
		bottom: 0;
		margin-bottom: 0;
		li {
			height: 5px;
			width: 20px;
			cursor: pointer;
			border-radius: 0;
			@include transition(0.8s);
			color: #000;
			background-color: #000;
			&.active {
				width: 20px;
				height: 20px;
			}
		}
	}

	.flexbox {
		//position: relative;
		max-height: 550px;
		@include flexbox;
		flex-direction: column-reverse;
		@include md {
			flex-direction: row;
		}
		.textWrapper {
			position: absolute;
			background-color: rgba(#fff, .6);
			padding: 10px;
			margin: 10px;
			border-radius: 0;
			bottom: 0;
			right: 0;
			z-index: 2;
			max-width: 60%;
		}

		.newsSlogan {
			margin-top: 20px;
			width: 100%;
			padding-left: 10px;
			border-top-right-radius: 12px;
			background: rgba(255,255,255,0.5);
			@include flexbox;
			flex-direction: column;
			justify-content: center;
			@include md {
				width: 50%;
				max-width: 50%;
				margin: 0;
				&.noimg {
					margin: 0 auto;
				}
			}
			&.noimg {
				margin: 0 auto;
			}
			text-align: left;

			.title {
				font-size: 28px;
				text-align: center;
				@include md {
					text-align: left;
				}
			}

			.showMore {
				text-align: left;
				margin-top: 10px;
			}
		}

		.newsImg {
			width: 100%;
			text-align: center;
			padding: 0 10px;
			&.noimg {
				width: 0;
			}
			@include md {
				width: 50%;
				&.noimg {
					width: 0;
				}
			}

			img {
				max-height: 500px;
				max-width: 100%;
			}
		}
	}
}

.neubau {
	.carousel.slide {
		max-width: 100%;
		.carousel-item {
			max-width: 100%;
			img {
				width: auto;
				max-height: 80vh;
			}
		}
	}
	embed,
	object {
		width: 100%;
		height: 90vh;
	}
}


#tooltip {
	position: absolute;
	background-color: rgba(#000, 0.8);
	color: #fff;
	background-color: #fff;
	color: #000;
	border-radius: 4px;
	@include transition(opacity 0.3s, left .5s, top .5s);
	visibility: hidden;
	opacity: 0;
	top: -20px;
	@include defBorder;
	@include defBoxShadow;
	padding: 6px 12px;
	&.visible {
		visibility: visible;
		opacity: 1;
		z-index: 1010;
		@include transition(opacity 0.3s 0.3s, left 0.5s, top 0.5s);
	}
}
.mg {
	margin: 20px 0;
}

.fragebogen {
	.smallText {
		font-size: 18px;
	}
	form {
		width: 100%;
		.mg {
			margin: 20px 0;
		}
		.form-group.checkbox {
			@include flexbox;
			justify-content: flex-start;
			align-items: baseline;
			margin: 0;
			@include md {
				width: 50%;
			}
			@include lg {
				width: 40%;
			}
			label {
				min-width: 80%;
				margin: 0;
			}
			input[type=checkbox], input[type=radio] {
				@include transform(scale(1.4));
				&:focus {
					outline: 0;
					border: 0;
					box-shadow: none;
				}
			}
		}
	}
	.successMail {
		margin: 20px 0;
		padding: 20px;
		border-radius: 4px;
		background-color: rgba(#0f0, .4);
	}
	.failMail {
		margin: 20px 0;
		padding: 20px;
		border-radius: 4px;
		background-color: rgba(#f00, .4);
	}
}
