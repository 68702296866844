nav#navbar.navbar {
	background-color: rgba($mc,0.9);
	color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	z-index: 100;
	height: 70px;
	@include lg {
		height: 85px;
	}

	&.fixed-top {
		height: 55px;
		padding-top: 0.1rem;
		@include maxmd {
			.navbar-brand {
				height: 53px;
			}
		}

		.navbar-toggler {
			align-items: flex-start;
			margin-top: 5px;

			.navbar-toggler-icon {
				width: 1em;
				height: 1em;
			}
		}
		@include lg {
			height: 65px;
		}

		img.logo {
			height: 60px;
		}
	}

	&:hover {
		background-color: rgba(43,93,144,1);
	}

	img.logo {
		height: 60px;
		@include transition(0.5s);
		@include lg {
			height: 80px;
		}
	}

	a:hover img.logo {
		-webkit-filter: drop-shadow(2px 2px 5px #000);
		filter: drop-shadow(2px 2px 5px #000);
	}

	.navbar-brand {
		padding: 0;
	}

	ul.menu {
		list-style: none;
		margin: 0;
		padding-left: 0;
		@include flexbox;
		flex-direction: row;
	}

	ul.menu a, a.nav-link {
		position: relative;
		color: rgba(255,255,255,.5);
		padding: 10px;
		&:hover {
			color: #fff;
			text-decoration: none;
		}
	}

	ul.menu a:before, a.nav-link:before {
		content: "";
		position: absolute;
		height: 2px;
		bottom: 4px;
		left: 2px;
		right: 2px;
		background-color: #fff;
		visibility: hidden;
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	ul.menu a:hover:before, a:hover:before {
		visibility: visible;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
	@include maxmd {
		#navbarSupportedContent {
			background-color: $mc-dark;
			padding: 10px;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;

			a.nav-link {
				color: #fff;
			}
		}
	}
}

.emptyNavSpace {
	height: 0;

	&.expand {
		height: 85px;
	}
}

.infoRows {
	.row {
		padding: 5px 0;
		@include transition(0.3s);

		&:nth-child(even) {
			background-color: #eee;
		}

		&:hover {
			color: #000;
			background-color: #ddd;
		}
	}
}
