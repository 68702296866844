#current-news {

  .news {
    
    &:not(#news-0) {
      margin-top: 15px;
      border-top: 1px solid #aaa;
      padding-top: 15px;
    }
  }
  .news-content {
    display: flex;
    align-items: flex-start;
    .news-header {
      width: 24%;
      flex-shrink: 0;
      img {
        width: 100%;
      }
    }
    .news-body {
      padding-left: 20px;
    }
  }

}